<template>

  <main class="container ">

    <br />

    <!-- <div
      class="p-4 p-md-5 mb-4 text-white rounded bg-dark rounded-lg border border-light"
    >
      <div class="row">
        <div class="col-md-8 px-0">
          <h1 class="display-4 fst-italic">The Goal of the Symposium</h1>
          <p class="lead my-3">
            In today's World, Remote Sensing is an important tool used in many
            fields, and the main field in which Remote Sensing is operational
            24/7 is Atmospheric Sciences. This technology is used to detect,
            understand and solve meteorological, climatological and hydrological
            problems; it makes important contributions to the detection and
            follow-up of ordinary or extreme weather events. In addition, Remote
            Sensing has undeniable contributions to forecasters and decision
            makers in terms of very short-term weather forecasting and early
            warning. Considering that our country's studies on Meteorological
            Remote Sensing are not sufficient in number and level, it is aimed
            to increase the studies on this subject throughout the country with
            METEO UZAL 2021. Within the framework of these objectives, it is
            aimed to share knowledge and experience with local and foreign
            invited speakers and paper holders who will participate in the
            symposium.
          </p>
        </div>
        <div class="col-md-4 ml-2 pl-2 mt-5">
          <img
            class="border rounded bg-dark"
            src="https://airbus-h.assetsadobe2.com/is/image/content/dam/corporate-topics/publications/backgrounders/MetOp-SG-Copyright-ESA.jpg?wid=1920&amp;fit=fit,1&amp;qlt=85,0"
            width="90%"
            alt=""
          />
        </div>
      </div>
    </div> -->

    <div class="row rounded border border-dark p-2">
      <div class="col-md-7">
        <h1 class="pb-4 mb-4 fst-italic"></h1>

        <article class="blog-post">
          <h4 class="blog-post-title">Symposium Topics</h4>
          <!-- <p class="blog-post-meta">January 1, 2014 by <a href="#">Mark</a></p> -->
          <p>
            In the fields of meteorology, climatology and hydrology, all
            academic and practical subjects based on remote sensing, as well as
            remote sensing systems developed and being developed in these
            fields, can be presented at METEO IRS 2021.
          </p>
        </article>

        <article class="blog-post">
          <h4 class="blog-post-title">Application</h4>
          <p>
            The languages of the symposium will be English and Turkish. It is
            possible to attend the symposium with or without a paper.
            Participants who want to present a paper should send their abstracts
            on the symposium website until 1 November 2021, in accordance with
            the sample text they will obtain from the symposium website.
          </p>
        </article>
        <!-- /.blog-post -->

        <article class="blog-post">
          <h4 class="blog-post-title">Acceptance and Submissions</h4>
          <p>
            Papers presented to the symposium will be classified under different
            session titles. Sessions will be held online, and participants will
            be given 20 minutes for each session to present their papers. In
            addition, at the beginning of each session, there will be invited
            papers. Those who want to make a comprehensive presentation on
            symposium issues can also present their situation and justifications
            to the Symposium Organizing Committee. You can find the abstract template <a href='/files/AbstractTemplate.docx' download>here</a>.
          </p>
        </article>
        <!-- /.blog-post -->

        <!-- /.blog-post -->

        <!-- <nav class="blog-pagination" aria-label="Pagination">
            <a class="btn btn-outline-primary" href="#">Eski</a>
            <a class="btn btn-outline-secondary disabled" href="#" tabindex="-1" aria-disabled="true">Yeni</a>
        </nav> -->
      </div>

      <div class="col-md-5 mt-4 ">
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Symposium Program</h4>
          <p>You can find the symposium program <router-link :to="{ name: 'symprog' }" exact
              >here</router-link
            >.</p>
        </div>
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Important Dates</h4>
          <ul>
            <li>Abstract Submission Deadline: 1 November 2021</li>
            <li>Notification of Acceptance (for abstracts): 10 November 2021</li>
            <li>Symposium Dates: 1-3 December 2021</li>
            <li>Full Paper Submission Deadline: 30 December 2021</li>
          </ul>
        </div>
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Registration</h4>
          <p>
            The fee for participation with presentation is 100 TL for Turkey and
            50 EURO for other countries. 50% discount will be applied for
            student. Participation as a listener is free of charge. For the
            registration
            <router-link :to="{ name: 'register' }" exact
              >click here</router-link
            >.
          </p>

          <ul class="p-0" style="list-style-type:none;">
            <li>Account Information</li>
            <li>Bank & Branch Name: Ziraat Bankası Başkent Şubesi</li>
            <li>TRY: TR50 0001 0016 8339 0090 6150 04</li>
            <li>EUR: TR93 0001 0016 8339 0090 6150 06</li>
            <li>SWIFT: TCZBTR2A</li>
            <li>Account Name: TMMOB Meteoroloji Mühendisleri Odası</li>
            <li>
              Participants can present only one paper for oral or poster
              presentations with one registration fee.
            </li>
            <li>VAT is included in the registration fees.</li>
          </ul>
        </div>

        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Contact</h4>
          <ul>
            <li>meteouzal@itu.edu.tr</li>
            <li>www.meteouzal.itu.edu.tr</li>
            <li>meteouzal (instagram)</li>
          </ul>
        </div>
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Flyer</h4>
          <p>
            You can find the flyer of the symposium
            <router-link :to="{ name: 'poster' }" exact>here</router-link>.
          </p>
        </div>
        <!-- <div class="p-4">
      <h4 class="fst-italic">Arşiv</h4>
      <ol class="list-unstyled mb-0">
        <li><a href="#">March 2014</a></li>
        <li><a href="#">February 2014</a></li>
        <li><a href="#">January 2014</a></li>
      </ol>
    </div> -->

        <!-- <div class="p-4">
      <h4 class="fst-italic">Elsewhere</h4>
      <ol class="list-unstyled">
        <li><a href="#">GitHub</a></li>
        <li><a href="#">Twitter</a></li>
        <li><a href="#">Facebook</a></li>
      </ol>
    </div> -->
      </div>
    </div>
    <!-- /.row -->

    <br />
  </main>
</template>

<script>
// @ is an alias to /src


export default {
  name: "Home",
  components: {},
};
</script>