<template>
  <div class="container">
    <header class="blog-header py-3">
      <div class="row flex-nowrap justify-content-between align-items-center">
        <div class="col-2 pt-1">
          <img src="@/assets/meteouzal-logo-TR.png" style="width:100%" alt="" />
                    <div class="switch-toggle">
                <input type="checkbox" id="bluetooth" @click.prevent="langDegis">
                <label for="bluetooth"></label>
            </div>
        </div>
        <div class="col-9 text-center mx-auto">
          <div class="row">
            <a class="blog-header-logo custom-text text-dark" href="#"
              >Meteorolojide Uzaktan Algılama Sempozyumu
            </a>
          </div>
          <div class="row pt-2">
            <a class="blog-header-logo text-dark" href="#">METEO UZAL 2021</a>
          </div>
          <div class="row pt-1">
            <a class="blog-header-logo text-dark" href="#">1-3 Aralık 2021</a>
          </div>
        </div>
        <div class="col-1 pl-0 ml-0">
          <!-- <a class="link-secondary" href="#" aria-label="Search">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="mx-3" role="img"
                        viewBox="0 0 24 24">
                        <title>Search</title>
                        <circle cx="10.5" cy="10.5" r="7.5" />
                        <path d="M21 21l-5.2-5.2" />
                    </svg>
                </a> -->
          <div class="row mb-3 pl-0 ">
            <router-link
              class="px-0 text-dark a"
              v-if="!currentUser"
              :to="{ name: 'giris' }"
              >Giriş</router-link
            >
          </div>
          <div class="row pl-0">
            <router-link
              class="px-0 text-dark a"
              v-if="!currentUser"
              :to="{ name: 'kayit' }"
              >Kayıt</router-link
            >
          </div>
          <div class="row pl-0">
            <router-link
              class="px-0 text-dark a"
              v-if="currentUser"
              :to="{ name: 'profil' }"
              >{{ currentUser.first_name }}</router-link
            >
          </div>
          <div class="row  mt-3 pl-0">
            <a
              v-if="currentUser"
              class="px-0 mx-0 text-dark a"
              @click.prevent="logOut"
            >
              Çıkış
            </a>
          </div>
        </div>
      </div>
    </header>

    <div class="row align-content-between mt-2">
      <nav class="col-12 nav d-flex justify-content-around">
        <router-link class="text-dark a" :to="{ name: 'anasayfa' }" exact
          >Anasayfa</router-link
        >
        <router-link class="text-dark a" :to="{ name: 'kurullar' }" exact
          >Kurullar</router-link
        >
        <router-link class="text-dark a" :to="{ name: 'konusmaci' }" exact
          >Konuşmacılar</router-link
        >
        <router-link class="text-dark a" :to="{ name: 'duzenleyentr' }" exact
          >Düzenleyenler</router-link
        >
        <router-link class="text-dark a" :to="{ name: 'destekleyentr' }" exact
          >Destekleyenler</router-link
        >

        <!-- <router-link
          class="btn btn-outline-secondary"
          :to="{ name: 'takvim' }"
          exact
          >Takvim</router-link
        > -->

        <!-- <a class="p-2 link-secondary" href="/urunler">Ürünler</a> -->
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    langDegis() {
      this.$store.state.PrefLang = "en";
      this.$router.push("/en/home");
    },
  },
};
</script>

<style scoped>
.a {
  font-size: 1.5vw;
  cursor: pointer;
}

.a:hover {
  opacity: 0.6;
  text-decoration: none;
}

.router-link-exact-active {
  cursor: default;
  opacity: 0.9;
  text-decoration: none;
}

.router-link-exact-active:hover {
  opacity: 0.9;
  text-decoration: none;
}

.blog-header-logo {
  font-size: 2vw;
}

.blog-header-logo:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1600px) {
  .a {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .a {
    font-size: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .blog-header-logo {
    font-size: 30px;
  }
}

@media screen and (max-width: 575px) {
  .blog-header-logo {
    font-size: 15px;
  }
}

@media screen and (max-width: 575px) {
  .custom-text {
    font-size: 13px;
  }
}


.switch-toggle {
    height: 30px;
}

.switch-toggle input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    z-index: -2;
}

.switch-toggle input[type="checkbox"] + label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
    
}

.switch-toggle input[type="checkbox"] + label::before {
    position: absolute;
    content: 'EN';
    font-size: 15px;
    text-align: center;
    line-height: 30px;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    color: #fff;
    background-color: #00b33c;
    box-shadow: -3px -3px 5px rgba(255,255,255,.5),
                3px 3px 5px rgba(0,0,0, .25);
    transition: .3s ease-in-out;
}

.switch-toggle input[type="checkbox"]:checked + label::before {
    left: 50%;
    content: 'TR';
    color: #fff;
    background-color: #00b33c;
    box-shadow: -3px -3px 5px rgba(255,255,255,.5),
                3px 3px 5px #00b33c;
}
</style>
