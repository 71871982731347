<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="col-md-6 mx-auto">
        <div class="card">
          <div class="card-header bg-seconday text-dark">
            <h4>Giriş</h4>
          </div>
          <div class="card-body">
            <Form @submit="handleLogin">
              <div class="form-group">
                <label for="username">Eposta</label>
                <Field name="username" type="text" class="form-control" />
                <ErrorMessage name="username" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="password">Şifre</label>
                <Field name="password" type="password" class="form-control" />
                <ErrorMessage name="password" class="error-feedback" />
              </div>
              <br />
              <div class="form-group">
                <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Giriş</span>
                </button>
              </div>
              <br />
              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <br />
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "Giris",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message = "Hatalı eposta veya şifre" || error.response;
        }
      );
    },
  },
};
</script>
