<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="col-md-6 mx-auto">
        <div class="card">
          <div class="card-header bg-seconday tex-white">
            <h4>Kayıt</h4>
          </div>
          <div class="card-body">
            <Form @submit="handleRegister">
              <div v-if="!successful">
                <div class="form-group">
                  <label for="email">Email</label>
                  <Field name="email" type="email" class="form-control" />
                  <div
                    v-if="errormessage.email"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ errormessage.email.toString() }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">Şifre</label>
                  <Field name="password" type="password" class="form-control" v-model="password" />
                  <div
                    v-if="errormessage.password"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ errormessage.password.toString() }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="password2">Şifre Onayla</label>
                  <Field
                    name="password2"
                    type="password"
                    class="form-control"
                    v-model="password2"
                  />
                  <div
                    v-if="errormessage.password"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ errormessage.password.toString() }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="first_name">Ad</label>
                  <Field name="first_name" type="text" class="form-control" />
                  <div
                    v-if="errormessage.first_name"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ errormessage.first_name.toString() }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="last_name">Soyad</label>
                  <Field name="last_name" type="text" class="form-control" />
                  <div
                    v-if="errormessage.last_name"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ errormessage.last_name.toString() }}
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <button class="btn btn-primary btn-block" :disabled="loading" >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Kayıt Ol
                  </button>
                </div>
              </div>
            </Form>
            <br />
            <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
            >
              {{ message }}
              <router-link v-if="successful" :to="{ name: 'giris' }"
                >buradan giriş yapabilirsiniz.</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </main>
</template>

<script>
import { Form, Field } from "vee-validate";



export default {
  name: "Kayit",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      errormessage: "",
      password : "",
      password2 : "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    passIsSame(){
        return this.password == this.password2
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/login");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      user.username = user.email;
      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = "Kaydınız başarıyla oluşturuldu, " || data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.errormessage = error.response.data;
          this.message = "Hatalı kısımları düzeltiniz." || error.message;
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
