import { createRouter, createWebHashHistory } from "vue-router";
import Anasayfa from "../views/TR/Anasayfa.vue";
import Giris from "../views/TR/Giris.vue";
import Kayit from "../views/TR/Kayit.vue";
import Home from "../views/EN/Home.vue";
import Login from "../views/EN/Login.vue";
import Register from "../views/EN/Register.vue";
import store from "@/store";
import AppTR from "@/components/AppTR.vue";
import AppEN from "@/components/AppEN.vue";

// lazy-loaded
const Kurullar = () => import("../views/TR/Kurullar.vue");
const DeKurumlar = () => import("../views/TR/DeKurumlar.vue");
const DuKurumlar = () => import("../views/TR/DuKurumlar.vue");
const Takvim = () => import("../views/TR/Takvim.vue");
const Konusmaci = () => import("../views/TR/Konusmaci.vue");
const Profil = () => import("../views/TR/Profil.vue");
const Afis = () => import("../views/TR/Afis.vue");
const SempProg = () => import("../views/TR/Program.vue")

const Lecturer = () => import("../views/EN/Speakers.vue");
const DeCorp = () => import("../views/EN/DeCorp.vue");
const DuCorp = () => import("../views/EN/DuCorp.vue");
const Boards = () => import("../views/EN/Committee.vue");
const Profile = () => import("../views/EN/Profile.vue");
const Poster = () => import("../views/EN/Poster.vue");


store.state.PrefLang =  navigator.language.substr(0, 2);

if (store.state.PrefLang === "tr" || store.state.PrefLang === "en") {
  console.log(store.state.PrefLang);
} else {
  store.state.PrefLang = "en";
  console.log(store.state.PrefLang);
}

const routes = [
  {
    path: "/",
    redirect: `/${store.state.PrefLang}/`,
  },
  {
    path: "/tr/",
    redirect: "/tr/home",
    component: AppTR,
    children: [
      {
        path: "home",
        name: "anasayfa",
        component: Anasayfa,
      },
      {
        path: "login",
        name: "giris",
        component: Giris,
      },
      {
        path: "register",
        name: "kayit",
        component: Kayit,
      },
      {
        path: "takvim",
        name: "takvim",
        component: Takvim,
      },
      { path: "committee", name: "kurullar", component: Kurullar },
      { path: "supporting", name: "destekleyentr", component: DeKurumlar },
      { path: "organizing", name: "duzenleyentr", component: DuKurumlar },
      { path: "speakers", name: "konusmaci", component: Konusmaci },
      { path: "profile", name: "profil", component: Profil },
      { path: "flyer", name: "afis", component: Afis },
      { path: "program", name: "sempozyumprogramı", component: SempProg },
    ],
  },
  {
    path: "/en/",
    redirect: "/en/home",
    component: AppEN,
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "login",
        name: "login",
        component: Login,
      },
      {
        path: "register",
        name: "register",
        component: Register,
      },
      { path: "committee", name: "boards", component: Boards },
      { path: "supporting", name: "destekleyenen", component: DeCorp },
      { path: "organizing", name: "duzenleyenen", component: DuCorp },
      { path: "speakers", name: "lecturers", component: Lecturer },
      { path: "profile", name: "profile", component: Profile },
      { path: "flyer", name: "poster", component: Poster },
      { path: "program", name: "symprog", component: SempProg },
    ],
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', redirect:"/" },
  { path: '/:pathMatch(.*)', name: 'bad-not-found', redirect:"/" },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/tr/login",
    "/tr/register",
    "/tr/home",
    "/tr/",
    "/tr/committee",
    "/tr/supporting",
    "/tr/organizing",
    "/tr/speakers",
    "/tr/flyer",
    "/tr/program",
    "/en/home",
    "/en/register",
    "/en/login",
    "/en/",
    "/en/committee",
    "/en/supporting",
    "/en/organizing",
    "/en/speakers",
    "/en/flyer",
    "/en/program",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.state.auth.user;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next(`/${store.state.PrefLang}/login`);
  } else {
    next();
  }
});

export default router;
