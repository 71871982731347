import { createStore } from "vuex";
import { auth } from "./auth.module";

export default createStore({
  state: {
    APIData: "",
    UserTypeData: "",
    UserKurumData:"",
    PrefLang:"",
  },
  mutations: {},
  actions: {}, 
  modules: {
    auth,
  },
});
