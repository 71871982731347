<template>
  <main class="container ">
    <br />

    <div
      class="p-4 p-md-5 mb-4 text-white rounded bg-dark rounded-lg border border-light"
    >
      <div class="row">
        <div class="col-md-8 px-0">
          <h1 class="display-4 fst-italic">Sempozyumun Hedefi</h1>
          <p class="lead my-3">
            Uzaktan Algılama, günümüz dünyasında pek çok alanda kullanılan
            önemli bir araç olup, Uzaktan Algılama’nın 7 gün 24 saat operasyonel
            olarak kullanıldığı başlıca alan da Atmosfer Bilimleri’dir. Bu
            teknoloji meteorolojik, klimatolojik ve hidrolojik sorunların
            saptanmasında, anlaşılmasında ve çözülmesinde; olağan ya da aşırı
            hava olaylarının tespit ve takibinde önemli katkılar sunmaktadır.
            Ayrıca, Uzaktan Algılama'nın çok kısa süreli hava tahmini ve erken
            uyarı anlamında da tahmincilere ve karar vericilere yadsınamaz
            katkıları bulunmaktadır. Meteorolojik Uzaktan Algılama konusunda
            ülkemiz çalışmalarının yeterli sayıda ve düzeyde bulunmadığı
            düşünülürse, METEO UZAL 2021 ile birlikte ülke genelinde bu konudaki
            çalışmaların artırılması hedeflenmektedir. Bu hedefler çerçevesinde
            sempozyuma katılım sağlayacak yerli ve yabancı çağrılı konuşmacılar
            ve bildiri sahipleri ile bilgi ve tecrübe paylaşımının
            gerçekleştirilmesi amaçlanmaktadır.
          </p>
        </div>
        <div class="col-md-4 ml-2 pl-2 mt-5">
          <img
            class="border rounded bg-dark"
            src="https://airbus-h.assetsadobe2.com/is/image/content/dam/corporate-topics/publications/backgrounders/MetOp-SG-Copyright-ESA.jpg?wid=1920&amp;fit=fit,1&amp;qlt=85,0"
            width="90%"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="row rounded border border-dark p-2">
      <div class="col-md-7">
        <h1 class="pb-4 mb-4 fst-italic"></h1>

        <article class="blog-post">
          <h4 class="blog-post-title">Sempozyum Konuları</h4>
          <!-- <p class="blog-post-meta">January 1, 2014 by <a href="#">Mark</a></p> -->
          <p>
            Meteoroloji, klimatoloji ve hidroloji alanlarında, uzaktan
            algılamaya dayalı gerek akademik ve gerekse uygulamaya yönelik tüm
            konular ile bu alanlarda geliştirilen ve geliştirilmekte olan
            uzaktan algılama sistemleri, METEO UZAL 2021’de sunulabilir.
          </p>
        </article>

        <article class="blog-post">
          <h4 class="blog-post-title">Başvuru</h4>
          <p>
            Sempozyumun dili Türkçe ve İngilizce olacaktır. Sempozyuma bildirili
            veya bildirisiz katılmak mümkündür. Bildiri sunmak isteyen
            katılımcıların sempozyum web sayfasından temin edecekleri örnek
            metne uygun olarak özetlerini, 1 Kasım 2021 tarihine kadar yine
            sempozyum web sayfası üzerinden göndermeleri gerekmektedir.
          </p>
        </article>
        <!-- /.blog-post -->

        <article class="blog-post">
          <h4 class="blog-post-title">Kabul ve Sunuşlar</h4>
          <p>
            Sempozyuma sunulan bildiriler, değişik oturum başlıkları altında
            sınıflandırılacaktır. Oturumlar çevrimiçi (online) olarak
            gerçekleştirilecek olup, her bir oturumda bildirilerin
            sunulabilmeleri için katılımcılara 20’şer dakikalık süre
            verilecektir. Ayrıca, her bir oturumun başında çağrılı bildiriler de
            yer alacaktır. Sempozyum konularında geniş kapsamlı sunum yapmak
            isteyenler de durum ve gerekçelerini Sempozyum Yürütme Kurulu’na
            sunabilirler. Bildiri özet şablonuna <a href='/files/OzetSablonu.docx' download>buradan</a> ulaşabilirsiniz.
          </p>
        </article>
        <!-- /.blog-post -->

        <!-- /.blog-post -->

        <!-- <nav class="blog-pagination" aria-label="Pagination">
            <a class="btn btn-outline-primary" href="#">Eski</a>
            <a class="btn btn-outline-secondary disabled" href="#" tabindex="-1" aria-disabled="true">Yeni</a>
        </nav> -->
      </div>

      <div class="col-md-5 mt-4 ">
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Afiş ve Bülten</h4>
          <p>
            Sempozyumun afişine
            <router-link :to="{ name: 'afis' }" exact>buradan</router-link>
            ulaşabilirsiniz.
          </p>
          <p>Nisan 2021 bültenimize <a href='/files/METEOUZALSAYI1.pdf' download>buradan</a> ulaşabilirsiniz.</p>
          <p>Şubat 2022 bültenimize <a href='/files/METEOUZALSAYI2.pdf' download>buradan</a> ulaşabilirsiniz.</p>
          <p>Mart 2022 bültenimize <a href='/files/METEOUZALSAYI3.pdf' download>buradan</a> ulaşabilirsiniz.</p>
        </div>
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Sempozyum Program</h4>
          <p> Sempozyum programına <router-link :to="{ name: 'sempozyumprogramı' }" exact
              >buradan</router-link
            > ulaşabilirsiniz.</p>
        </div>
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Önemli Tarihler</h4>
          <ul>
            <li>Özetlerin Gönderilmesi: 1 Kasım 2021</li>
            <li>Değerlendirme Sonuçlarının Açıklanması: 10 Kasım 2021</li>
            <li>Sempozyum Tarihi: 1-3 Aralık 2021</li>
            <li>Tam Metinlerin Gönderilmesi: 30 Aralık 2021</li>
          </ul>
        </div>
        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">Kayıt</h4>
          <p>
            Sempozyuma bildirili katılım ücreti delege için 100 TL ve öğrenci
            için 50 TL olup, dinleyici olarak katılım ücretsizdir. Kayıt için
            <router-link :to="{ name: 'kayit' }" exact
              >buraya tıklayınız</router-link
            >.
          </p>

          <ul class="p-0" style="list-style-type:none;">
            <li>Hesap Bilgileri</li>
            <li>Banka ve Şube Adı: Ziraat Bankası Başkent Şubesi</li>
            <li>IBAN: TR50 0001 0016 8339 0090 6150 04</li>
            <li>Hesap Adı: TMMOB Meteoroloji Mühendisleri Odası</li>
            <li>
              Katılımcılar, tek kayıt ücreti ile sözlü veya poster sunumları
              için yalnızca bir bildiri sunabilirler.
            </li>
            <li>Kayıt ücretlerine KDV dahildir.</li>
          </ul>
        </div>

        <div class="p-4 mb-3 bg-light rounded border border-info">
          <h4 class="fst-italic">İletişim</h4>
          <ul>
            <li>meteouzal@itu.edu.tr</li>
            <li>www.meteouzal.itu.edu.tr</li>
            <li>meteouzal (instagram)</li>
          </ul>
        </div>
        <!-- <div class="p-4">
      <h4 class="fst-italic">Arşiv</h4>
      <ol class="list-unstyled mb-0">
        <li><a href="#">March 2014</a></li>
        <li><a href="#">February 2014</a></li>
        <li><a href="#">January 2014</a></li>
      </ol>
    </div> -->

        <!-- <div class="p-4">
      <h4 class="fst-italic">Elsewhere</h4>
      <ol class="list-unstyled">
        <li><a href="#">GitHub</a></li>
        <li><a href="#">Twitter</a></li>
        <li><a href="#">Facebook</a></li>
      </ol>
    </div> -->
      </div>
    </div>
    <!-- /.row -->

    <br />
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Anasayfa",
  components: {},
};
</script>
