
<template>
<footer class="blog-footer">
  <p>METEO UZAL © 2021 Copyright</p>
</footer>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Footer',
  components: {
  }
}
</script>

<style scoped>


</style>
