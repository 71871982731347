<template>
  <NavbarEN></NavbarEN> 
  <router-view/>
</template>


<script>

import NavbarEN from '@/components/NavbarEN.vue';

export default {
  name: 'AppEN',
  components: {
      NavbarEN,
   },
  // created(){
  //   this.$notify("YOUTUBE Live Stream: <a href='https://www.youtube.com/channel/UCYMKPXnIn3a58h_IFVWUWlg'>METEO IRS'2021</a>");
  // },
};
</script>