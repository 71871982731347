<template>
  <NavbarTR></NavbarTR>
  <router-view />
</template>


<script>
import NavbarTR from "@/components/NavbarTR.vue";

export default {
  name: "AppTR",
  components: {
    NavbarTR,
  },
  created() {
    this.$notify("Mart 2022 bültenimize <a href='/files/METEOUZALSAYI3.pdf' download>buradan</a> ulaşabilirsiniz.");
  },
};
</script>